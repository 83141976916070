
















import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface FlexibleDuoPicture {
  picture: Picture
  htmltext: string
  reversed: boolean
}

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleDuoPicture',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<FlexibleDuoPicture>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
